<template>
    <v-row justify="center" class="py-5" align="center" style="height: 100%">
        <v-col style="text-align: center" cols="12" sm="6">
            <router-link :to="{ name: 'brand', params: { alias: this.$route.params.alias }, query: { email: this.$route.query.email } }">
            <BrandImage :alias="brand.alias" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
            </router-link>
            <BrandCard>
                <!-- <template #menu>
                    <v-menu offset-y open-on-click open-on-hover bottom left close-delay="100">
                        <template #activator="{ on, attrs }">
                            <v-btn icon :color="primaryTextColor" v-bind="attrs" v-on="on" >
                                <font-awesome-icon :icon="['far', 'ellipsis-v']" fixed-width style="font-size: 16px;"/>
                            </v-btn>
                        </template>
                        <v-list class="ma-0 pa-0">
                            <v-list-item :to="{ name: 'profile' }">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'user']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Profile
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'user-account-list' }">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'folder']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Accounts
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'dashboard' }">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'chart-line']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Dashboard
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'id-badge']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Service Admin
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider/>
                            <v-list-item @click="onLogout">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'shield-alt']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Logout
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template> -->
                <template #title>
                    Email verification
                </template>
                <template v-if="checkEmail">
                    <v-card-text>
                    <p>We sent a a verification email to:</p>
                    <p>
                        {{ email }}
                        <v-btn class="no-print" @click="resetInput" icon :color="primaryColor"> <!-- :style="primaryIconStyle" -->
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    </v-card-text>
                </template>
                <template v-if="!checkEmail">
                    <v-form @submit.prevent="login" @keyup.enter.native="login" class="ma-4 pb-4">
                    <p>
                        To change your email preferences, enter your email address:
                    </p>
                    <v-text-field v-model="email" ref="emailInput" dense solo :color="primaryColor" :error-messages="errorInvalidEmail"> <!-- :style="primaryIconStyle" -->
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/> <!-- style="font-size: 20px;" --> <!-- TODO: use brand accent color? or primary color? for the icon -->
                        </template>
                    </v-text-field>
                    <v-btn @click="login" elevation="4" :style="primaryButtonStyle">Continue</v-btn> <!-- TODO: use brand accent color? or primary color? for the button -->
                    </v-form>
                </template>
            </BrandCard>
            <v-alert dense type="success" border="left" v-if="checkEmail" class="mt-8">
                <template v-slot:prepend>
                    <font-awesome-icon fixed-width :icon="['fas', 'envelope']" :style="primaryIconStyle"/>
                </template>
                <span class="ml-2 text-body-1">Check your inbox to continue</span>
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidEmail } from '@/sdk/input';
import BrandCard from '@/components/BrandCard.vue';
import BrandImage from '@/components/BrandImage.vue';

export default {
    components: {
        BrandCard,
        BrandImage,
    },
    data: () => ({
        email: null,
        errorInvalidEmail: null,
        errorTimeout: null,
        action: null,
        loginActionTimestamp: null,
        checkEmail: false,
        emailContact: null, // { brand, email, enabled, disabled, topics }
        selected: [], // selected topics
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            brand: (state) => state.brand,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            isAuthenticatedReady: 'isAuthenticatedReady',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://emplus.io';
        },
    },
    watch: {
        focus() {
            if (this.isAuthenticated(this.$route.query.email)) {
                this.redirect(this.$route.query.email);
            } else {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('emailInput'); }, 1);
                });
            }
        },
    },
    methods: {
        isAuthenticated(email) {
            if (!email || !Array.isArray(this.session?.email)) {
                return false;
            }
            const lcEmail = email.toLowerCase();
            return this.session.email.map((item) => item.toLowerCase()).includes(lcEmail);
        },
        async login() {
            if (Number.isInteger(this.loginActionTimestamp) && this.loginActionTimestamp + 500 > Date.now()) {
                return;
            }
            this.loginActionTimestamp = Date.now();
            if (!isValidEmail(this.email)) {
                this.errorInvalidEmail = 'Enter a valid email address';
                if (this.errorTimeout) {
                    clearTimeout(this.errorTimeout);
                }
                this.errorTimeout = setTimeout(() => {
                    this.errorInvalidEmail = null;
                    this.errorTimeout = null;
                }, 5000);
                this.activate('emailInput');
                return;
            }
            // if the input email doesn't match the query parameter, update the query and continue
            if (this.email !== this.$route.query.email) {
                this.$router.replace({ name: 'brand-authenticate', params: { alias: this.$route.params.alias }, query: { email: this.email, action: this.$route.query.action } });
            }
            // if user's session is already authenticated for the input email address, continue to the requested action
            if (this.isAuthenticated(this.email)) {
                this.redirect(this.email);
                return;
            }
            this.sendVerificationEmail();
        },
        async sendVerificationEmail() {
            try {
                this.$store.commit('loading', { sendVerificationEmail: true });
                const nextRoute = this.routeForAction(this.$route.query.action);
                const response = await this.$client.main().authn.start({ brandprofile_alias: this.$route.params.alias, email: this.email, route: nextRoute });
                if (response?.sent) {
                    // this.$bus.$emit('snackbar', { type: 'success', headline: 'Sent verification email' });
                    this.checkEmail = true;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to send verification email' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to send verification email' });
            } finally {
                this.$store.commit('loading', { sendVerificationEmail: false });
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        resetInput() {
            this.checkEmail = false;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('emailInput'); }, 1);
            });
        },
        routeForAction(action = 'main') {
            switch (action) {
            case 'subscribe':
                return 'brand-subscribe';
            case 'unsubscribe':
                return 'brand-unsubscribe';
            case 'main':
            default:
                return 'brand';
            }
        },
        redirect(email) {
            const nextRoute = this.routeForAction(this.$route.query.action);
            this.$router.replace({ name: nextRoute, params: { alias: this.$route.params.alias }, query: { email } });
        },
    },
    mounted() {
        // allow apps to construct a URL like http://contact.emplus.test/brand/example-brand/authenticate?email=sparky@example.com&action=subscribe so we auto-fill the email input with the email address and redirect user to the 'subscribe' page after authentication, again with the email address in the query parameters; if an action isn't provided, we'll redirect the user to the brand main page from where the user can subscribe or unsubscribe
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }
        if (this.isAuthenticated(this.$route.query.email)) {
            this.redirect(this.$route.query.email);
        } else {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('emailInput'); }, 1);
            });
        }
    },
};
</script>
